import Subcsription from 'components/Subcsription/Subcsription';
import { useDispatch } from 'react-redux';
import { subscription } from '../../redux/auth';
import subscriptions from 'data/subsriptions.json';

const freeSub = {
  id: 1,
  title: 'Free',
  type: 'Free',
  text: '',
  points: [
    '100 characters',
    'Premium + standard voices',
    '450+ different natural sounding voices',
    'SSML support',
    'Email support',
  ],
  availableCharacters: 100,
  price: 0,
};

const PriceList = () => {
  const dispatch = useDispatch();

  const date = new Date();
  const options = { timeZone: 'Asia/Kolkata' };
  const indiaDate = date.toLocaleString('en-US', options);

  const onFreeSubClick = () => {
    dispatch(
      subscription({
        name: 'Free',
        price: 0,
        availableCharacters: 100,
        startDate: indiaDate,
      })
    );
  };

  return (
    <div className="py-24 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Pricing
          </h2>
        </div>
        <ul>
          <Subcsription
            key={freeSub.id}
            onFreeSubClick={onFreeSubClick}
            subscription={freeSub}
          />
          {subscriptions.map(el => (
            <Subcsription key={el.id} subscription={el} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PriceList;
