import blog from '../../data/blog';

const Blog = () => {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-3xl px-5 lg:px-5">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Blog
          </h1>
        </div>
        <ul className="flex flex-col w-full gap-6 py-12">
          {blog.map(el => (
            <li
              className="flex flex-col-reverse gap-4 divide-y divide divide-accent md:flex-row"
              key={el.id}
            >
              <img
                className="rounded-xl max-h-72"
                src={el.url}
                width="292px"
                alt={el.title}
              />
              <div>
                <h2 className="text-base font-semibold leading-7 text-text-primary">
                  {el.title}
                </h2>
                <span className="block mt-2 text-sm text-text-gray font-semibold">
                  {el.date}
                </span>
                <p className="mt-2 text-base leading-7 text-text-primary">
                  {el.text}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Blog;
