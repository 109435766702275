import axios from 'axios';
import toast from 'react-hot-toast';

const URL = 'https://ttsbackend-production.up.railway.app/feedback';

export const getAllFeedback = async () => {
  try {
    const result = await axios.get(`${URL}`);

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendFeedback = async ({ text, name, email }) => {
  try {
    await axios.post(`${URL}/send`, { email, text, name });

    toast.success('Your feedback has been sent');
  } catch (error) {
    console.log(error);
  }
};

export const deleteFeedback = async id => {
  try {
    await axios.delete(`${URL}/${id}`);
    console.log(id);
    toast.success('Feedback has been successfully deleted');
  } catch (error) {
    console.log(error);
  }
};
