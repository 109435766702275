import axios from 'axios';

const URL = 'https://ttsbackend-production.up.railway.app/';

export const getTts = async () => {
  try {
    const result = await axios.get(`${URL}tts`);

    return result.data;
  } catch (error) {
    console.log(error);
  }
};
