import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logOut, isLoggedIn, user } from '../../redux/auth';
import { NavLink } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { BsList, BsX } from 'react-icons/bs';
import ProfileDpopdown from 'components/ProfileDpopdown/ProfileDpopdown';
import logo from '../../images/logo.png';

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'Pricing', to: 'pricing' },
  { name: 'Voices', to: 'voices' },
  { name: 'Blog', to: 'blog' },
  { name: 'Contact Us', to: 'feedback' },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isLogged = useSelector(isLoggedIn);
  const currentUser = useSelector(user);
  const dispatch = useDispatch();

  const hadleLogOutClick = () => {
    dispatch(logOut());
    setMobileMenuOpen(false);
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center mx-auto justify-between max-w-screen-xl p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <NavLink to="/" className="-m-1.5 p-1.5 font-semibold">
              <span className="sr-only">Your Company</span>
              <img src={logo} width={'160px'} alt="logo" />
            </NavLink>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <BsList className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <ul className="hidden lg:flex lg:gap-x-12">
            {navigation.map(item => (
              <li key={item.name}>
                <NavLink
                  to={item.to}
                  className="text-sm font-semibold leading-6 text-text-primary"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
            {currentUser.type === 'Admin' && (
              <li>
                <NavLink
                  to={'/admin'}
                  className="text-sm font-semibold leading-6 text-text-primary"
                >
                  Admin
                </NavLink>
              </li>
            )}
          </ul>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {isLogged ? (
              <ProfileDpopdown />
            ) : (
              <NavLink
                to="login"
                className="text-sm font-semibold leading-6 text-text-primary"
              >
                Log in
              </NavLink>
            )}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-text-primary/10">
            <div className="flex items-center justify-between">
              <NavLink href="#" className="-m-1.5 p-1.5 font-semibold">
                <span className="sr-only">Your Company</span>
                Logo
              </NavLink>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <BsX className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <ul className="space-y-2 py-6">
                  {navigation.map(item => (
                    <li key={item.name}>
                      <NavLink
                        to={item.to}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-gray-50"
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                  {currentUser.type === 'Admin' && (
                    <li>
                      <NavLink
                        to={'/admin'}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-gray-50"
                      >
                        Admin
                      </NavLink>
                    </li>
                  )}
                </ul>
                <div className="py-6">
                  {isLogged ? (
                    <>
                      <NavLink
                        to="profile"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-gray-50"
                      >
                        Profile
                      </NavLink>
                      <button
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-text-primary hover:bg-gray-50"
                        type="buttom"
                        onClick={hadleLogOutClick}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <NavLink
                      to="login"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-text-primary hover:bg-gray-50"
                    >
                      Log in
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 pointer-events-none transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
