import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, authError, isLoading, clearError } from '../../redux/auth';
import { NavLink } from 'react-router-dom';
import RegisterForm from 'components/RegisterForm/RegisterForm';

const Register = () => {
  const dispatch = useDispatch();

  const signInError = useSelector(authError);
  const loading = useSelector(isLoading);

  useEffect(() => {
    if (signInError) {
      dispatch(clearError());
    }
  }, [signInError, dispatch]);

  const onSubmit = ({ username, phone, password, email }) => {
    dispatch(
      register({
        ...(phone !== '' && { phone }),
        username,
        email,
        password,
      })
    );
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-24 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight primary-text">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <RegisterForm onSubmit={onSubmit} loading={loading} />
          <p className="mt-10 text-center text-sm text-gray-500">
            Are you already registered?{' '}
            <NavLink
              to="/login"
              className="font-semibold leading-6 text-accent hover:text-accent-hover"
            >
              Go to login page!
            </NavLink>
          </p>
        </div>
      </div>
    </>
  );
};

export default Register;
