const array = [
  {
    name: 'Enter text',
    description:
      'Enter the text that needs to be converted into speech, the free limit is 4000 characters, some voices support unlimited free use.',
    number: 1,
  },
  {
    name: 'Select language and voice',
    description:
      'Choose the language for the text and your preferred voice style, each language has multiple voice styles',
    number: 2,
  },
  {
    name: 'Convert text to speech',
    description:
      'Click the "Convert to Speech" button to start converting the text to speech, which may take a few minutes, longer texts will take longer.',
    number: 3,
  },
  {
    name: 'Listen and download',
    description:
      'After the text is converted to speech, you can listen to it online or download the audio file.',
    number: 4,
  },
];

const Tutorial = () => {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-3xl px-5 lg:px-5">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Quick Tutorial
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-3xl">
          <dl className="flex flex-col gap-14">
            {array.map(feature => (
              <div key={feature.name} className="relative flex flex-col gap-4">
                <dt className="flex items-center text-base text-center font-semibold leading-7 text-text-primary">
                  <div className="flex mr-4 h-10 w-10 items-center justify-center rounded-lg bg-accent">
                    <p className="text-white">{feature.number}</p>
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-text-primary">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
