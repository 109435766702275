import Hero from 'components/Hero/Hero';
import Tts from 'components/Tts/Tts';
import Tutorial from 'components/Tutorial/Tutorial';
import Scenarios from 'components/Scenarios/Scenarios';
import Faq from 'components/Faq/Faq';
import Features from 'components/Features/Features';

const Home = () => {
  return (
    <>
      <Hero>
        <Tts />
      </Hero>
      <Tutorial />
      <Scenarios />
      <Features />
      <Faq />
    </>
  );
};

export default Home;
