import { useState } from 'react';
import LangSelect from 'components/LangSelect/LangSelect';

import allVoices from '../../data/voices.json';

const DEFAULT_LANG = {
  code: 'en-US',
  language: 'English (United States)',
  flag: 'us',
};

const Voices = () => {
  const [language, setLanguage] = useState(DEFAULT_LANG);

  const filteredVoices = allVoices.filter(
    voice => voice.languageCodes === language.code
  );

  return (
    <div className="mx-auto max-w-7xl px-6 py-24">
      <div className="mx-auto max-w-2xl sm:text-center">
        <h1 className="text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
          Voices
        </h1>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Real aloud pro has more than 450+ Realistic Human-like sounding AI
          voices available in most languages. Below you can listen to the voice
          samples and decide which is most suitable for your needs.
        </p>
      </div>

      <LangSelect
        width={'full'}
        title={'Language'}
        value={language}
        onChange={setLanguage}
      />

      <ul className="flex flex-wrap justify-around gap-4 gap-y-6 mt-6">
        {filteredVoices.map(voice => (
          <li
            className="flex-1 z-10 flex items-center gap-4 min-w-72 w-full rounded-md  bg-white py-1.5 pl-3 p-2 text-left text-primary-text border border-solid border-primary-border sm:min-w-96"
            key={voice._id}
          >
            <img
              src={require(`../../images/Avatars/${voice.person.toLowerCase()}.jpg`)}
              className="rounded-full w-16 h-16"
              width={32}
              height={32}
              alt={voice.person}
            />
            <div className="flex flex-col w-full gap-2">
              <p className="text-lg leading-8 text-gray-600">
                {voice.person} <span>({voice.ssmlGender.toLowerCase()})</span>{' '}
                {voice.type}
              </p>
              <div className="relative w-full h-9 overflow-hidden cursor-default border border-solid border-primary-border rounded-md bg-white">
                <audio
                  className="w-full h-9"
                  controls
                  src={`data:audio/mp3;base64,${voice.audio}`}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Voices;
