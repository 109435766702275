import {
  BsTiktok,
  BsYoutube,
  BsBook,
  BsHeadphones,
  BsMortarboard,
  BsGlobe2,
  BsWindow,
} from 'react-icons/bs';
import { PiTelevision } from 'react-icons/pi';

const array = [
  {
    name: 'Video dubbing',
    subtitle: 'Youtube and TikTok voice generator',
    description:
      'As an AI voice generator, TTSMaker can generate the voices of various characters, which are often used in video dubbing of Youtube and TikTok. For your convenience, TTSMaker provides a variety of TikTok style voices for free use.',
    icons: [
      { id: 1, icon: BsTiktok },
      { id: 2, icon: BsYoutube },
    ],
  },
  {
    name: 'Audiobook reading',
    subtitle: 'Create and listen to audiobook content',
    description:
      'TTSMaker can convert text into natural speech, and you can easily create and enjoy audiobooks, bringing stories to life through immersive narration.',
    icons: [
      { id: 1, icon: BsBook },
      { id: 2, icon: BsHeadphones },
    ],
  },
  {
    name: 'Education & Training',
    subtitle: 'Teaching and Learning Languages',
    description:
      'TTSMaker can convert text to sound and read it aloud, can help you learn the pronunciation of words, and supports multiple languages, it has now become a useful tool for language learners.',
    icons: [
      { id: 1, icon: BsGlobe2 },
      { id: 2, icon: BsMortarboard },
    ],
  },
  {
    name: 'Marketing & Advertising',
    subtitle: 'Create voiceovers for video ads',
    description:
      "TTSMaker generates persuasive voice-overs to help marketers and advertisers explain a product's features to others, with high-quality audio.",
    icons: [
      { id: 1, icon: PiTelevision },
      { id: 2, icon: BsWindow },
    ],
  },
];

const Scenarios = () => {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-3xl px-5 lg:px-5">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Usage Scenarios
          </h2>
          <p className="mt-6 text-lg leading-8 text-text-primary">
            TTSMaker's text to speech can be used for the following main
            purposes.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-3xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {array.map(feature => (
              <div
                key={feature.name}
                className="relative bg-white rounded-md py-4 px-3 h-max text-left text-primary-text border border-solid border-primary-border focus:outline-none"
              >
                <dt className="mb-6 flex justify-center gap-4 text-base text-center font-semibold leading-7 text-text-primary">
                  {feature.icons.map(el => (
                    <div
                      key={el.id}
                      className="flex h-10 w-10 items-center justify-center rounded-lg bg-accent"
                    >
                      <el.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                  ))}
                </dt>
                <dt className="text-base text-center font-semibold leading-7 text-text-primary">
                  {feature.name}
                </dt>
                <dt className="text-base text-center font-semibold leading-7 text-accent">
                  {feature.subtitle}
                </dt>
                <dd className="mt-2 text-center text-base leading-7 text-text-primary">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Scenarios;
