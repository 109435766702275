import { Suspense, useEffect } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useDispatch, useSelector } from 'react-redux';
import { refreshUser, isRefresh } from '../redux/auth';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { RestrictedRoute, PrivateRoute } from './Routes';

import Layout from './Layout/Layout';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Register from 'pages/Register/Register';
import Profile from 'pages/Profile/Profile';
import Voices from 'pages/Voices/Voices';
import Prices from 'pages/Prices/Prices';
import Blog from 'pages/Blog/Blog';
import Feedback from 'pages/Feedback/Feedback';
import Admin from 'pages/Admin/Admin';

export const App = () => {
  const dispatch = useDispatch();

  const isRefreshing = useSelector(isRefresh);

  useEffect(() => {
    dispatch(refreshUser());
  }, [dispatch]);

  return (
    <>
      <PayPalScriptProvider
        options={{
          clientId:
            'AYs_stwm67jiaLOHmANnfcqdOidPIh3No2mhgjVaSzybw_huO8ZQJRvcJ6dvouIAu8MsvUqWp2a3iM7l',
          currency: 'USD',
          components: 'buttons',
        }}
      >
        <Suspense fallback={null}>
          {!isRefreshing && (
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/voices" element={<Voices />} />
                <Route path="/pricing" element={<Prices />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route
                  path="/profile"
                  element={<PrivateRoute component={Profile} redirectTo="/" />}
                />
                <Route
                  path="/admin"
                  element={<PrivateRoute component={Admin} redirectTo="/" />}
                />
                <Route
                  path="/login"
                  element={<RestrictedRoute component={Login} />}
                />
                <Route
                  path="/register"
                  element={<RestrictedRoute component={Register} />}
                />
              </Route>
            </Routes>
          )}
        </Suspense>
      </PayPalScriptProvider>
      <Toaster />
    </>
  );
};
