import { BsFillLightningChargeFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { TbCurrencyDollarOff } from 'react-icons/tb';
import { RiVoiceprintFill } from 'react-icons/ri';

const array = [
  {
    name: 'Fast speech synthesis',
    description:
      'We use a powerful neural network inference model that enables text-to-speech conversion in a short time.',
    icon: BsFillLightningChargeFill,
  },
  {
    name: 'Free for commercial use',
    description:
      'You will own 100% copyright of the synthesized audio file and may use it for any legal purpose, including commercial use.',
    icon: TbCurrencyDollarOff,
  },
  {
    name: 'More voices and features',
    description:
      'We are constantly updating this text-to-speech tool to support more languages and voices, as well as some new features.',
    icon: RiVoiceprintFill,
  },
  {
    name: 'Email and API supports',
    description:
      'We offer email support and text-to-speech API services. If you encounter any issues while using our services, please feel free to contact our support team via email or through our support page.',
    icon: BsFillEnvelopeFill,
  },
];

const Features = () => {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-3xl px-5 lg:px-5">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Features
          </h2>
          <p className="mt-6 text-lg leading-8 text-text-primary">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis.
            Suspendisse eget egestas a elementum pulvinar et feugiat blandit at.
            In mi viverra elit nunc.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-3xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {array.map(feature => (
              <div key={feature.name} className="relative">
                <dt className="text-base text-center font-semibold leading-7 text-text-primary">
                  <div className="flex ml-auto mr-auto mb-6 h-10 w-10 items-center justify-center rounded-lg bg-accent">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-text-primary">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
