import faqData from '../../data/faq.json';

const Faq = () => {
  return (
    <div className="max-w-3xl mx-auto px-5 min-h-sceen py-24 sm:py-32">
      <div className="flex w-full flex-col items-center">
        <h2 className="font-bold text-5xl mt-5 tracking-tight">FAQ</h2>
        <p className="text-text-primary text-xl mt-3">
          Frequenty asked questions
        </p>
      </div>
      <div className="grid divide-y divide-accent w-full mx-auto mt-8">
        {faqData.map(el => (
          <div className="py-5 w-full" key={el.id}>
            <details className="group w-full">
              <summary className="flex w-full justify-between items-center font-medium cursor-pointer list-none">
                <span className="text-text-primary">{el.title}</span>
                <span className="transition group-open:rotate-180">
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </summary>
              <p className="text-text-primary mt-3 group-open:animate-fadeIn">
                {el.text}
              </p>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
