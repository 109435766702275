const TextareaTts = ({ value, maxLength, onChange }) => {
  return (
    <div className="sm:col-span-2">
      <label
        htmlFor="text-to-speech"
        className="block text-sm font-semibold leading-6 text-primary-text"
      >
        Text to Speech
      </label>
      <div className="relative  mt-2.5">
        <textarea
          name="text-to-speech"
          id="text-to-speech"
          value={value}
          maxLength={maxLength}
          onChange={e => onChange(e.target.value)}
          className="block w-full min-h-40 outline-none rounded-md border-0 px-3.5 pb-8 py-2 text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
        />
        <span className="absolute bottom-1 rounded-sm left-2 backdrop-blur-sm text-text-gray text-sm font-semibold">{`${value.length} / ${maxLength}`}</span>
      </div>
    </div>
  );
};

export default TextareaTts;
