import { useSelector } from 'react-redux';
import { user } from '../../redux/auth';
import { NavLink } from 'react-router-dom';
import PaypalPayment from 'components/PayPalPayment/PayPalPayment';

const Subcsription = ({ subscription, onFreeSubClick }) => {
  const currentUser = useSelector(user);

  return (
    <li>
      <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-primary-border sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight text-text-primary">
            {subscription.title}
          </h3>
          {subscription.text && (
            <p className="mt-6 text-base leading-7 text-text-rimary">
              {subscription.text}
            </p>
          )}
          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-accent">
              What’s included
            </h4>
            <div className="h-px flex-auto bg-gray-100"></div>
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-text-rimary sm:grid-cols-2 sm:gap-6">
            {subscription.points.map((el, i) => (
              <li key={i} className="flex gap-x-3">
                <svg
                  className="h-6 w-5 flex-none text-accent"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clipRule="evenodd"
                  />
                </svg>
                {el}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex  -mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div className="w-full rounded-2xl bg-gray-50 py-10 text-center  border border-solid border-accent lg:flex lg:flex-col lg:justify-center lg:py-12">
            <div className="flex flex-col px-10 w-full">
              <p className="text-base font-semibold text-text-rimary">
                {currentUser.subscription?.name === subscription.title
                  ? 'Your current subscription'
                  : subscription.price !== 0
                  ? 'Pay once'
                  : ''}
              </p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-text-primary">
                  $
                  {!subscription.price !== 0
                    ? currentUser.discount
                      ? (
                          subscription.price * (1 - currentUser.discount) +
                          subscription.price
                        ).toFixed(2)
                      : subscription.price
                    : 0.0}
                </span>
                <span className="mb-4 text-sm font-semibold leading-6 tracking-wide text-text-rimary">
                  USD
                </span>
              </p>
              {!currentUser.email ? (
                <>
                  <p className="mb-4 text-text-rimary">
                    The service is available only to registered users
                  </p>
                  <NavLink
                    to={'/login'}
                    className="mb-4 block w-full rounded-md bg-accent px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                  >
                    Login to make a payment
                  </NavLink>
                </>
              ) : subscription.price !== 0 ? (
                <div className="flex w-full">
                  <PaypalPayment
                    amount={
                      (
                        subscription.price * (1 - currentUser.discount) +
                        subscription.price
                      ).toFixed(2) || subscription.price
                    }
                    characters={subscription.availableCharacters}
                    subscriptionName={subscription.title}
                  />
                </div>
              ) : (
                <>
                  <p className="mb-4 text-text-rimary">
                    The service is available only to registered users
                  </p>
                  <button
                    type="button"
                    onClick={onFreeSubClick}
                    disabled={
                      currentUser && currentUser.subscription.name === 'Free'
                    }
                    className="mb-4 block w-full rounded-md bg-accent px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed"
                  >
                    Select
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Subcsription;
