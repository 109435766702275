import { PayPalButtons } from '@paypal/react-paypal-js';
import { useSelector, useDispatch } from 'react-redux';
import { user, subscription } from '../../redux/auth';
import toast from 'react-hot-toast';

const PaypalPayment = ({ amount, characters, subscriptionName }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(user);
  const userEmail = currentUser.email;

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: `${subscriptionName} for ${userEmail}`,
          amount: {
            value: amount,
          },
          custom_id: userEmail,
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(details => {
      if (details && details.status === 'COMPLETED') {
        dispatch(
          subscription({
            id: details.id,
            name: subscriptionName,
            price: amount,
            availableCharacters: characters,
            startDate: details.create_time,
          })
        );
      }
    });
  };

  const onError = err => {
    toast.error('Transaction error, subscription is not activated');
  };

  const checkSubscription =
    currentUser?.subscription?.name === subscriptionName;

  return (
    <>
      <PayPalButtons
        className="w-full mt-6"
        style={{ layout: 'vertical', color: 'black' }}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        disabled={checkSubscription}
      />
    </>
  );
};

export default PaypalPayment;
