import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { user } from '../../redux/auth';
import axios from 'axios';
import LangSelect from '../LangSelect/LangSelect';
import { audioDevice } from 'data/audioDeviceProfiles';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from '../Select/Select';
import Range from '../Range/Range';
import TextareaTts from '../TextareaTts/TextareaTts';
import allVoices from '../../data/voices.json';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';

const DEFAULT_LANG = {
  code: 'en-US',
  language: 'English (United States)',
  flag: 'us',
};

const Tts = () => {
  const [text, setText] = useState('');
  const [ssml, setSsml] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [pitch, setPitch] = useState(0);
  const [device, setDevice] = useState(audioDevice[0]);
  const [language, setLanguage] = useState(DEFAULT_LANG);
  const [captcha, setCaptcha] = useState(null);
  const [availableCharacters, setAvailableCharacters] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);

  const { email, subscription } = useSelector(user);

  const filteredVoices = allVoices.filter(
    voice => voice.languageCodes === language.code
  );

  const [voice, setVoice] = useState({
    language,
    ...filteredVoices[0],
  });

  useEffect(() => {
    const savedText = localStorage.getItem('savedText');
    if (savedText) {
      setText(savedText);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('savedText', text);
  }, [text]);

  useEffect(() => {
    if (subscription) {
      setAvailableCharacters(subscription.availableCharacters);
    }
  }, [setAvailableCharacters, subscription]);

  const handleSubmit = async () => {
    if (text.trim() === '') {
      return;
    }

    if (!email) {
      toast.error('Please login');
      return;
    }

    if (subscription.availableCharacters - text.trim().length < 0) {
      toast.error('Not enough available characters');
      return;
    }

    try {
      const response = await axios.post(
        'https://ttsbackend-production.up.railway.app/synthesize/',
        {
          [ssml ? 'ssml' : 'text']: text.trim(),
          code: language.code,
          speed,
          pitch,
          effectsProfileId: device.value ? [device.value] : null,
          voice: {
            _id: voice._id,
            languageCodes: voice.languageCodes,
            name: voice.name,
            naturalSampleRateHertz: voice.naturalSampleRateHertz,
            person: voice.person,
            ssmlGender: voice.ssmlGender,
            type: voice.type,
          },
          ...(email !== '' && { email }),
        }
      );
      const audio = `data:audio/mp3;base64,${response.data.audioContent}`;

      if (audio) {
        setAudioSrc(audio);

        setAvailableCharacters(availableCharacters - text.trim().length);
      }
    } catch (error) {
      toast.error('Voice synthesis error, try again');
    }
  };

  const onChange = value => {
    setCaptcha(value);
  };

  return (
    <div className="flex flex-col items-center sm:flex-row sm:align-start sm:justify-center">
      <div className="w-full sm:w-auto">
        <div className="flex flex-col gap-2 sm:flex-row sm:gap-6">
          <LangSelect
            width={'72'}
            title={'Language'}
            value={language}
            onChange={setLanguage}
          />
          <Select
            title={'Supported voices'}
            value={voice.person}
            onChange={setVoice}
            array={filteredVoices}
          />
        </div>
        <div className="flex flex-col mt-4 mb-2 sm:flex-row sm:justify-between sm:mb-0 sm:mt-2">
          <div className="flex flex-col pt-px gap-2">
            <Range
              value={speed}
              name={'speed'}
              defaultValue={1}
              min={0.3}
              max={4}
              step={0.1}
              onChange={setSpeed}
            />
            <Range
              value={pitch}
              name={'pitch'}
              defaultValue={0}
              min={-20}
              max={20}
              step={1}
              onChange={setPitch}
            />
          </div>
          <div className="flex flex-col gap-4 sm:items-end">
            <Select
              title={'Audio device profile'}
              value={device ? device.name : 'Without effect'}
              Icon={device.icon}
              onChange={setDevice}
              array={audioDevice}
            />
            <div className="relative w-full h-9 overflow-hidden cursor-default border border-solid border-primary-border rounded-md bg-white sm:w-72">
              <audio className="w-full h-9" controls autoPlay src={audioSrc} />
            </div>
          </div>
        </div>
        <TextareaTts
          value={text}
          maxLength={email ? 4000 : 100}
          onChange={setText}
        />

        <div className="flex justify-end mb-4">
          {email && (
            <p className=" rounded-md mr-auto text-center text-sm font-semibold">
              Available characters: {availableCharacters}
            </p>
          )}
          <div className="flex gap-2">
            <button
              className={`rounded-md text-center text-sm font-semibold text-accent ${
                !ssml && 'underline underline-offset-2'
              } focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-accent`}
              onClick={() => setSsml(false)}
              type="button"
            >
              text
            </button>
            <button
              className={`rounded-md text-center text-sm font-semibold text-accent ${
                ssml && 'underline underline-offset-2'
              } focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-accent`}
              onClick={() => setSsml(true)}
              type="button"
            >
              ssml
            </button>
          </div>
        </div>
        {email ? (
          <>
            <ReCAPTCHA
              sitekey="6Levz9ApAAAAAP3WKtF-JSbnyijzC_man7N5Miuc"
              onChange={onChange}
            />
            <div className="mt-4 sm:mt-6">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!captcha}
                className="block w-full rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:opacity-40 disabled:cursor-not-allowed"
              >
                Convert to Speech
              </button>
            </div>
          </>
        ) : (
          <div className="mt-4 sm:mt-6">
            <NavLink
              to={'/login'}
              className="block w-full rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:opacity-40 disabled:cursor-not-allowed"
            >
              Log in to get a free subscription
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tts;
