import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { sendFeedback } from 'services/feedback';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required!')
    .min(2, 'Name must be at least 2 characters')
    .trim(),
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required!')
    .trim(),
  message: Yup.string()
    .required('Message is required!')
    .min(10, 'Message must be at least 10 characters')
    .max(4000)
    .trim(),
});

const Feedback = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = ({ message, name, email }, e) => {
    sendFeedback({ text: message, name, email });
    reset();
  };

  return (
    <div className="isolate px-6 py-24 sm:py-32 lg:px-8">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      ></div>
      <div className="mx-auto max-w-2xl text-center">
        <h1 className="text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
          Contact us
        </h1>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Contact us - we're just a click away from assisting you.
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="mx-auto mt-16 max-w-xl sm:mt-20"
      >
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-text-primary"
            >
              Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                {...register('name')}
                autoComplete="name"
                className="block w-full rounded-md border-0 outline-none px-3.5 py-2 text-text-primary shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
              />
              {errors.name && (
                <span className="absolute text-xs text-error font-semibold">
                  {errors.name?.message}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-text-primary"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                {...register('email')}
                autoComplete="email"
                className="block w-full rounded-md border-0 outline-none px-3.5 py-2 text-text-primary shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
              />
              {errors.email && (
                <span className="absolute text-xs text-error font-semibold">
                  {errors.email?.message}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-text-primary"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                {...register('message')}
                rows="4"
                className="block w-full rounded-md border-0 outline-none px-3.5 py-2 text-text-primary shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
              ></textarea>
              {errors.message && (
                <span className="absolute text-xs text-error font-semibold">
                  {errors.message?.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
          >
            Let's talk
          </button>
        </div>
      </form>
    </div>
  );
};

export default Feedback;
