import { NavLink } from 'react-router-dom';
import logo from '../../images/logo.png';
import { BsTwitterX, BsInstagram, BsYoutube } from 'react-icons/bs';

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'Pricing', to: 'pricing' },
  { name: 'Voices', to: 'voices' },
  { name: 'Blog', to: 'blog' },
  { name: 'Contact Us', to: 'contact' },
];

const Footer = () => {
  return (
    <footer>
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <NavLink
            to="/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              <img src={logo} width={'160px'} alt="logo" />
            </span>
          </NavLink>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-primary-text sm:mb-0">
            {navigation.map(item => (
              <li key={item.name}>
                <NavLink to={item.to} className="hover:underline me-4 md:me-6">
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <hr className="my-6 border-primary-border sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="flex gap-1 text-sm text-text-gray sm:text-center">
            © {new Date().getFullYear()} <p>Read Aloud Pro.</p>
            All Rights Reserved.
          </span>
          {/* <div className="flex gap-4 items-center mt-4 sm:justify-center sm:mt-0">
            <a href="/" className="text-text-gray">
              <BsTwitterX size={16} />
              <span className="sr-only">X page</span>
            </a>
            <a href="/" className="text-text-gray">
              <BsInstagram size={16} />
              <span className="sr-only">Instagram page</span>
            </a>
            <a href="/" className="text-text-gray">
              <BsYoutube size={20} />
              <span className="sr-only">YouTube page</span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
